@keyframes spin {
  0% {
    transform: translateZ(0) rotate(0);
  }

  100% {
    transform: translateZ(0) rotate(360deg);
  }
}

@media (max-width: 456px) {
  .freeze-ui:before {
    left: calc(50% - 40px) !important;
  }
}

.freeze-ui {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  background-color: #fff;
  opacity: 0.8;
  transition: opacity 0.25s;
}

.freeze-ui.is-unfreezing {
  opacity: 0;
}

.freeze-ui:after {
  content: attr(data-text);
  display: block;
  max-width: 125px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-family: Roboto,Helvetica Neue,sans-serif;
  color: #343a40;
  text-align: center;
  text-transform: uppercase;
}

.freeze-ui:before {
  content: "";
  display: block;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: transparent #228ae6 #228ae6;
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  will-change: transform;
  animation: spin 0.75s infinite ease-in-out;
}